@import "../../sass/abstracts/_variables.scss";
@import "../../sass/components/frosted";

.stone-age {
    border-radius: 2.5rem;
    width: 100px;
    height: 100px;
    padding-inline: 3% 3%;
  }
  
  .poorGuy {
    padding-inline: 3% 3%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
  
    &__h1 {
      border-radius: inherit;
  
      &-alert {
        border-radius: inherit;
        color: rgba($font-light, 1.0);
      }
    }
  }