@import '../../sass/abstracts/variables';
@import '../../sass/components/button';

.mobileBtn {
    position: fixed;
    z-index: 10;
    text-align: center;
    font-weight: 1000;
    cursor: pointer;
    color: rgba($footer-dark, 1);

    &__body {
        border-radius: 50rem;
        position: absolute;
        opacity: 0.5;
        background-image: linear-gradient(to bottom right, rgba($color-aqua, 0.8), rgba($color-primary-light, 0.8), rgba($color-primary, 0.8), rgba($color-primary-dark, 0.8), rgba($color-primary, 0.8), rgba($color-primary-dark, 0.8), rgba($color-aqua, 0.8));
        top: 70rem;
        text-decoration: none;

        transition: 0.5s ease-in-out;

        &:hover {
            opacity: 1.0;
            box-shadow: 0.5rem 0.5rem 2rem 0.5rem rgba($color-aqua, 0.8);
            color: rgba($color-aqua, 1);
        }
        &:active {
            box-shadow: 0.5rem 0.5rem 2rem 0.5rem rgba($color-gold, 0.8);
            background-image: linear-gradient(to bottom right, rgba($font-dark, 0.8), rgba($font-light, 0.8), rgba($font, 0.8), rgba($font-light, 0.8), rgba($font-dark, 0.8), rgba($font, 0.8), rgba($font-light, 0.8));
            color: rgba($footer-dark, 1);
        }

    }

}