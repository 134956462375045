@import "../../sass/abstracts/variables";
@import "../../sass/components/frosted";
@import "../../sass/layouts/grid";
@import "../../sass/base/utilities";
@import "../../sass/base/animations";

.contact {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 5% 5%;
    margin: 0 auto;   

    &__h2 {
        color: rgba($font-light, 1);
        transition: all 0.5s ease-in-out;
        cursor: pointer;

        &:hover {
            scale: 1.005;
            color: rgba($font-light, 1);
            box-shadow: 0.2rem 0.2rem 0.2rem rgba($color-primary-light, 0.8);
            box-shadow: 0 0 3rem 1rem rgba($font-light, 0.6);
            
            //Frosted Glass Effect
            border-radius: 1.6rem;
            background-image: linear-gradient(to bottom right, $font-light, $font, $font-dark, $font, $font-light, 0.8);
            backdrop-filter: blur(1.5rem);
            -webkit-backdrop-filter: blur(1.5rem);
            border: 1px solid rgba($font-dark, 0.803);
            position: relative;
            background: inherit;
            overflow: hidden;
            // Allow contents to be seen & hovered above the glass
            z-index: 1;

        }

        // Container:before
        &:before {
            background: inherit;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            box-shadow: inset 0 0 200rem rgba($font-light, 0.5);
            filter: blur(1.2rem);
            // Allow contents to be seen & hovered above the glass
            z-index: -1;
        }

        &:active {
            scale: 1.00;
            color: rgba($font, 1);
            box-shadow: 0 0 0 0;
        }
    }

    &__googleMap {
        border-radius: 2rem;
        cursor: pointer;
        // Move-in from Right
        animation-name: moveInRight;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out; 

        transition: all 0.5s ease-in-out;

        &:hover {
            box-shadow: 0 0 3rem 1rem rgba($font-light, 0.7);
        }
    }
}