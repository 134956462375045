.feature-box {
    background-color: rgba($color-white, .8);
    font-size: 1.5rem;
    padding: 2.5rem;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .15);
    transition: all .3s ease-in;
    

    &__icon {
        font-size: 6rem;
        margin-bottom: 0.5rem;
        display: inline-block;
        background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
        -webkit-background-clip: text;
        color: transparent;
    }

    &__text {
        font-size: $default-font-size;
    }

    &:hover {
        transform: translateY(-1.5rem) scale(1.03);
    }
}