body {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    /* font-size: 1rem; */
    line-height: 1.7;
    color: $color-secondary-dark;
    padding: 3rem; /* 30px */
    box-sizing: border-box;
}

.heading-primary {
    color: $color-white;
    text-transform: uppercase;
    /* Remove shaky behaviour in children animations */
    backface-visibility: hidden;
    margin-bottom: 6rem; /* 60px */

    &--main {
        display: block; /* <span> is inline by default */
        font-size: 6rem; /* 60px */
        font-weight: 400;
        letter-spacing: 3.5rem; /* 35px */

        /* Animation */
        animation-name: moveInLeft;
        animation-duration: 6s;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
    }

    &--sub {
        display: block;
        font-size: 2rem; /* 20px */
        font-weight: 700;
        letter-spacing: 1.74rem; /* 17.4px */

        /* Animation */
        animation-iteration-count: 1;
        animation: moveInRight 6s ease-out;
    }
}

.heading-secondary {
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
    background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
    // Does not take up full line widht of block element
    display: inline-block;

    // Background gets clipped where the text exactly is
    -webkit-background-clip: text;

    // Make text transparent, thus we can see background-image
    color: transparent;
    letter-spacing: 2px;
    transition: all .2s;

    &:hover {
        transform: skewY(2deg) skewX(15deg) scale(1.1);
        text-shadow: .5rem 1rem 2rem rgba($color-black, .2); // x y blur rgba()
    }
}

.heading-tertiary {
    font-size: $default-font-size;
    font-weight: 700;
    text-transform: uppercase;
}

.paragraph {
    font-size: $default-font-size;
    
    &:not(:last-child) {
        margin-bottom: 3rem;
    }
}