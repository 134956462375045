.composition {
    position: relative;

    &__photo {
        width: 55%;
        box-shadow: 0 1.5rem 4rem rgba($color-black, .3);
        border-radius: 2px;
        position: absolute;
        z-index: 10;
        transition: all .2s;
        // Creating outline offset
        outline-offset: 2rem;

        &--p1 {
            left: 0;
            top: -2rem;
        }

        &--p2 {
            right: 0;
            top: 2rem;
        }

        &--p3 {
            left: 20%;
            top: 10rem;
        }

        &:hover {
            // Create a border without offset
            outline: 1.5rem solid $color-primary;
            transform: scale(1.05) translateY(-.5rem);
            box-shadow: 0 2.5rem 4rem rgba($color-black, .6);
            // Overlap other images
            z-index: 20;
        }
    }

    // composition:hover => Select composition photos not hovered
    // composition:hover composition:not(:hover)
    &:hover &__photo:not(:hover) {
        transform: scale(0.9) translateY(.5rem);
    }
}