@import '../../sass/abstracts/variables';
@import '../../sass/abstracts/mixins';
@import '../../sass/layouts/grid';
@import '../../sass/layouts/header';
@import '../../sass/base/animations';
@import '../../sass/components/frosted';

.products {
    //background-image: linear-gradient(to bottom left, $color-primary-light, $color-primary, $color-primary-dark, $color-secondary-light, $color-secondary, $color-secondary-dark);

    &__heading {
        //background-image: linear-gradient(to left, $color-primary-light,$color-primary-dark);
        margin-top: 2rem;
        padding-inline: 3%;
        //text-align: center;
        

        &--h2 {
            cursor: pointer;
            text-align: center;
            font-weight: 100;
            font-weight: 600;
            color: rgba($font, 1);
            //color: rgba($color-font-cert, 1);
            margin-left: 1rem;
            transition: 0.5s;

            &:hover {
                scale: 1.005;
                color: rgba($font-light, 1);
                box-shadow: 0 0 3rem 1rem rgba($font-light, 0.6);

                //Frosted Glass Effect
                border-radius: 1.6rem;
                background: linear-gradient(to bottom right, $font-light, $font, $font-dark, 0.8);
                //box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.3);
                backdrop-filter: blur(1.5rem);
                -webkit-backdrop-filter: blur(1.5rem);
                border: 1px solid rgba($font-dark, 0.803);
                position: relative;
                background: inherit;
                overflow: hidden;
                // Allow contents to be seen & hovered above the glass
                z-index: 1;

            }

            // Container:before
            &:before {
                background: inherit;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                box-shadow: inset 0 0 200rem rgba($font-light, 0.5);
                filter: blur(1.2rem);
                // Allow contents to be seen & hovered above the glass
                z-index: -1;
            }

            &:active {
                scale: 1.00;
                color: rgba($font, 1);
                box-shadow: 0;
            }
        }
    }
}