.section-about {
    background-color: $color-light-grey-1;
    padding: 25rem 0;
    /*
    .header {
    height: 95vh; // To almost 100%
    background-image: linear-gradient(
        to right bottom, 
        rgba($color-primary-light, 0.8), 
        rgba($color-primary-dark, 0.8)), 
        url('../img/hero.jpg');
        background-size: cover; // to fit the box
        background-position: top; // Top of img always stays top
        position: relative; // parent of .logo-box 
        clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
    */
    // height: 95vh - clip-path: 75vh = 20vh;
    // Move section-about 20vh to top
    margin-top: -20vh;
}

.section-features {
    padding: 20rem 0;
    background-image: linear-gradient(
        to right bottom, 
        rgba($color-primary-light, 0.8), 
        rgba($color-primary-dark, 0.8)), 
        url('');
    
    background-size: cover;
    transform: skewY(-7deg);
    // Move up to cover white-space
    margin-top: -10rem;

    // All direct children only
    // Instead of all children elements
    // Otherwise, icon, h3, text will also skew => Weird
    & > * {
        transform: skewY(7deg);
    }
}

.section-tours {
    background-color: $color-light-grey-1;
    padding: 25rem 0 15rem 0;
    margin-top: -10vh;
}

.section-stories {
    position: relative;
    padding: 15rem 0;
    background-color: transparent;
    overflow: hidden;
}

.section-book {
    position: relative;
    padding: 15rem 0;
    background-image: linear-gradient(to right bottom, $color-primary-light, $color-primary-dark);
    background-color: transparent;
}

.book {
    background-image: linear-gradient(
        105deg, 
        rgba($color-white, .9) 0%, 
        rgba($color-white, .9) 50%,
        transparent 50%
        ), 
        url('');
    background-size: 100%; // 50% will have a grid of 4 img repeating itself
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba($color-black, 0.3);

    height: 50rem;

    &__form {
        width: 50%;
        padding: 6rem;
    }
}