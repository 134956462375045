@import '../../sass/abstracts/variables';
@import '../../sass/abstracts/mixins';
@import '../../sass/layouts/grid';
@import '../../sass/layouts/header';
@import '../../sass/base/animations';
@import '../../sass/components/frosted';


// Width >= 850
.about-container {
    //background-color: rgba($color-primary-dark, 1);
    //height: 100vh;
    background-image: linear-gradient(to right bottom, rgba($color-primary, 0.5), rgba($color-primary-light, 0.5)), url("../../images/home/background.jpg");
    background-size: contain;
    position: relative;
    background-position: top;
}

.aboutGt {
    //background-image: linear-gradient(to bottom left, $color-primary-light, $color-primary, $color-primary-dark, $color-secondary-light, $color-secondary, $color-secondary-dark);

    &__heading {
        //background-image: linear-gradient(to left, $color-primary-light,$color-primary-dark);
        padding-inline: 3%;
        //text-align: center;
        
        &--h2 {
            left: 3rem;
            font-weight: 100;
            font-weight: 600;
            color: rgba($font, 1);
            //color: rgba($color-font-cert, 1);
            margin-left: 1rem;
            transition: 0.5s;

            &:hover {
                scale: 1.005;
                color: rgba($font-light, 1);
                //box-shadow: 0.2rem 0.2rem 0.2rem rgba($color-primary-light, 0.8);
                box-shadow: 0 0 3rem 1rem rgba($font-light, 0.6);

                //Frosted Glass Effect
                border-radius: 1.6rem;
                background: linear-gradient(to bottom right, $font-light, $font, $font-dark, 0.8);
                //box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.3);
                backdrop-filter: blur(1.5rem);
                -webkit-backdrop-filter: blur(1.5rem);
                border: 1px solid rgba($font-dark, 0.803);
                position: relative;
                background: inherit;
                overflow: hidden;
                // Allow contents to be seen & hovered above the glass
                z-index: 1;
            }

            // Container:before
            &:before {
                background: inherit;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                box-shadow: inset 0 0 200rem rgba($font-light, 0.5);
                filter: blur(1.2rem);
                // Allow contents to be seen & hovered above the glass
                z-index: -1;
            }

            &:active {
                scale: 1.00;
                color: rgba($font, 1);
                box-shadow: 0;
            }
        }
    }

    &__inner {
        //background-image: linear-gradient(to left, $color-primary-light,$color-primary-dark);
        //rgba($color-primary-light,1);
        display: flex;
        flex-direction: row;
        
        
        &--text {
            
            &-p {
                cursor: pointer;
                font-size: 1.8rem;
                font-weight: 600;
                color: rgba($font, 1);
                padding-inline: 4%;
                transition: 0.5s;
                margin-left: 2rem;

                &:hover {
                    border-radius: 1.5rem;
                    background-image: linear-gradient(to bottom right, $color-primary-dark, $color-secondary-light, $color-secondary, $color-secondary-light, $color-primary-dark, $color-primary);
                    color: rgba($color-white, 1);
                    box-shadow: 0 0 3rem 2rem rgba($color-white, 0.7);
                    //color: linear-gradient(to bottom right, $color-primary-light, $color-primary, $color-primary-dark);
                }

                &:active {
                    scale: 1.0;
                    color: rgba($font, 1);
                }
            }
        }

        &--cert {
            font-size: 2.4rem;
            font-weight: 600;
            color: rgba($color-secondary-dark, 1);
            
        }
        
        &--ul {
            margin-left: 3rem;
        }

        &--container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-left: 5rem;

            &--img {

            }
        }
        &--imgrow {
            
        }

        &--imgCol {

        }

        &--about {

            &-aboutImg {
                transition: 1s ease-in;
                margin: 2rem 0rem;
                //margin-bottom: 4rem;

                transition: 0.5s;
                animation-name: moveInRight;
                animation-duration: 1s;
                animation-iteration-count: 1;
                animation-timing-function: ease-out; 
    
                &:hover {
                    border-radius: 1.5rem;
                    scale: 1.02;
                    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba($color-primary-dark, 0.6);
                }

                &:active{
                    scale: 1.0;
                    box-shadow: 0 0 0 0;
                }
                //display: flex;
                //justify-content: flex-start;
                //align-items: flex-start;
                //margin-right: 4rem;
                //padding-inline: 5%;
            }
        }
        
    }

    &__intro {
        margin-left: 7rem;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;

        &--row {
            transition: all 0.5s ease-in-out;

            &:hover {
                border-radius: 1.5rem;
                background-image: linear-gradient(to bottom right, $color-primary-dark, $color-secondary-light, $color-secondary, $color-secondary-light, $color-primary-dark, $color-primary);
                color: rgba($color-white, 1);
                box-shadow: 0 0 3rem 2rem rgba($color-white, 0.7);
            }

            &-h3 {
                color: rgba($font-light, 1);                
            }

            &-ul {
                //list-style: none;
                margin-left: 6rem;

                display: flex;
                flex-flow: column wrap;
                align-items: first baseline;
            }

            &-li {
                color: rgba($font-light, 1);
                text-decoration: none;
                text-align: center;
            }
        }
    }

    &__bottom {
        //background-color: rgba($color-primary, 1);
        text-align: center;

        &--row {
            text-align: center;
            //background-color: rgba($color-primary, 1);
            display: flex;
            align-items: center;
            margin-bottom: 0rem;
            color: rgba($color-secondary-dark, 1);

            &-text {
                //margin: 0 25rem; 
                padding-inline: 5%;

                &--h2 {
                    font-size: 5rem;
                    justify-self: flex-end;
                    //margin-left: 15rem;
                    padding-inline: 5%;
                    margin: 20rem 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: rgba($font, 1);
                    transition: 0.5s ease-in-out;

                    &:hover {
                        border-radius: 1.5rem;
                        //scale: 1.05;
                        color: rgba($font-dark, 1);
                        box-shadow: 0 0 3rem 2rem rgba($color-white, 0.7);
                        //background-image: linear-gradient(to bottom, $color-primary-light, $color-primary, $color-primary-dark);
                        background-image: linear-gradient(to bottom right, $color-primary-dark, $color-secondary-light, $color-secondary, $color-secondary-light, $color-primary-dark, $color-primary);
                    }
    
                    &:active {
                        scale: 1.00;
                        box-shadow: 0 0 0 0;
                        color: rgba($font, 1);
                        background-image: "";
                    }
                }                
            }

            &-slideshow {

            }
        }

    }
}

// Width < 850
.aboutLt {
    //background-image: linear-gradient(to bottom left, $color-primary-light, $color-primary, $color-primary-dark, $color-secondary-light, $color-secondary, $color-secondary-dark);
    
    &__heading {
        //background-color: rgba($color-primary-light,1);
        padding-inline: 21%;
        text-align: center;

        &--h2 {
            cursor: pointer;
            font-weight: 100;
            font-weight: 600;
            color: rgba($font, 1);
            //color: rgba($color-font-cert, 1);
            margin-left: 1rem;
            transition: 0.5s;

            &:hover {
                scale: 1.005;
                color: rgba($font-light, 1);
                box-shadow: 0.2rem 0.2rem 0.2rem rgba($color-primary-light, 0.8);
                box-shadow: 0 0 3rem 1rem rgba($font-light, 0.6);

                //Frosted Glass Effect
                border-radius: 1.6rem;
                background-image: linear-gradient(to bottom right, $font-light, $font, $font-dark, $font, $font-light, 0.8);
                //box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.3);
                backdrop-filter: blur(1.5rem);
                -webkit-backdrop-filter: blur(1.5rem);
                border: 1px solid rgba($font-dark, 0.803);
                position: relative;
                background: inherit;
                overflow: hidden;
                // Allow contents to be seen & hovered above the glass
                z-index: 1;

            }

            // Container:before
            &:before {
                background: inherit;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                box-shadow: inset 0 0 200rem rgba($font-light, 0.5);
                filter: blur(1.2rem);
                // Allow contents to be seen & hovered above the glass
                z-index: -1;
            }

            &:active {
                scale: 1.00;
                color: rgba($font, 1);
                box-shadow: 0 0 0 0;
            }
        }
    }

    &__inner {
        //background-color: rgba($color-primary-light,1);
        display: flex;
        flex-direction: column;
        //padding-inline: 20%;
        overflow: hidden;
        
        &--text {
            //margin-top: 5rem;

            &-p {
                font-size: 1.8rem;
                font-weight: 600;
                color: rgba($font, 1);
                padding-inline: 4%;
                transition: 0.5s;

                &:hover {
                    border-radius: 1.5rem;
                    scale: 1.01;
                    color: rgba($font-light, 1);
                }

                &:active {
                    scale: 1.0;
                    color: rgba($font, 1);
                }
            }
        }
        
        &--ul {
            margin-left: 3rem;
        }

        &--container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-left: 5rem;

            &--img {

            }
        }
        &--imgrow {
            
        }

        &--imgCol {

        }

        &--aboutImg {
            //display: flex;
            //justify-content: flex-start;
            //align-items: flex-start;
            //margin-right: 4rem;
            //padding-inline: 5%;
            display: flex;
            margin: 2rem 2rem;
            justify-content: center;
            align-items: center;
            transition: 0.5s;

            animation-name: moveInRight;
            animation-duration: 1s;
            animation-iteration-count: 1;
            animation-timing-function: ease-out; 
            &:hover {
                border-radius: 1.5rem;
                scale: 1.02; 
            }
            &:active {
                scale: 1.0;
            }
            &::after {
                scale: 1.0;
            }
        }
        
    }

    &__intro {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        margin: 0 auto;

        &--row {
            width: auto;
            transition: all 0.5s ease-in-out;

            &:hover {
                border-radius: 1.5rem;
                background-image: linear-gradient(to bottom right, $color-primary-dark, $color-secondary-light, $color-secondary, $color-secondary-light, $color-primary-dark, $color-primary);
                color: rgba($color-white, 1);
                box-shadow: 0 0 3rem 2rem rgba($color-white, 0.7);
            }

            &-h3 {
                color: rgba($font-light, 1);
                text-align: center;
            }

            &-ul {
                display: flex;
                flex-flow: column wrap;
                align-items: first baseline;
            }

            &-li {
                color: rgba($font-light, 1);
                text-decoration: none;
            }
        }
    }

    &__bottom {
        //background-color: rgba($color-primary, 1);
        text-align: center;

        &--row {
            text-align: center;
            //background-color: rgba($color-primary, 1);
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0rem;
            color: rgba($color-secondary-dark, 1);

            &-text {
                
                &--h2 {
                    font-size: 5rem;
                    justify-self: flex-end;
                    //margin-left: 15rem;
                    padding-inline: 5%;
                    margin: 20rem 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: rgba($font, 1);
                    transition: 0.5s;
                    
                    &:hover {
                        border-radius: 1.5rem;
                        //scale: 1.05;
                        color: rgba($font-dark, 1);
                        box-shadow: 0 0 3rem 2rem rgba($color-white, 0.7);
                        background-image: linear-gradient(to bottom, $color-primary-light, $color-primary, $color-primary-dark);
                    }
    
                    &:active {
                        scale: 1.00;
                        box-shadow: 0 0 0 0;
                        color: rgba($font, 1);
                        background-image: "";
                    }
                }                
            }

            &-slideshow {
                //padding-inline: 5%;
            }
        }

    }
}