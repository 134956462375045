@import '../../sass/abstracts/variables';
@import '../../sass/abstracts/mixins';
@import '../../sass/layouts/grid';
@import '../../sass/layouts/header';
@import '../../sass/components/frosted';

.ggt-container {
    display: flex;
    flex-direction: column;
    //background-color: rgba($color-primary, 1);

    &__session {
        display: flex;
        padding-inline: 2%;
        justify-content: space-evenly;

        &--img {
            border-radius: 2rem;
            margin: 1rem 0.5rem;
            width: 100%;
            height: 48.5rem;
            transition: all 0.5s ease-in-out;
            padding-inline: 1%;
   
            &:hover {
                cursor: pointer;
                scale: 1.005;
                border-radius: 2rem;
                transform: translateY(-0.2rem);
                box-shadow: 0 0 3rem 1rem rgba($color-aqua, 1);
            }
            &:active {
                transform: translateY(0);
                box-shadow: 0 0 0 0;
            }
        }
    }

}

.gt-container {
    display: flex;
    flex-direction: column;
    //background-color: $color-primary;

    &__session {
        display: flex;
        padding-inline: 2%;
        justify-content: space-evenly;

        &--img {
            border-radius: 2rem;
            margin: 1rem 0.5rem;
            width: 100%;
            height: 48.5rem;
            transition: all 0.5s ease-in-out;
            //padding-inline: 1%;
   
            &:hover {
                cursor: pointer;
                scale: 1.005;
                border-radius: 2rem;
                transform: translateY(-0.2rem);
                box-shadow: 0 0 3rem 1rem rgba($color-aqua, 1);
            }
            &:active {
                transform: translateY(0);
                box-shadow: 0 0 0 0;
            }
        }
    }

}

.lt-container {
    display: flex;
    flex-direction: column;
    background-color: $color-primary;
    padding-inline: 5% 5%;

    &--img {
        border-radius: 2rem;
        //padding-inline: 5%;
        margin-bottom: 3rem;
        transition: all 0.5s ease-in-out;

        &:hover {
            cursor: pointer;
            border-radius: 1.5rem;
            transform: scale(1.01) translateY(-0.5rem);
            box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba($color-aqua, 0.8);
        }
        &:active {
            transform: scale(1.0) translateY(0.5rem);
            box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba($color-gold, 0.25);
            &:visited {
                box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba($footer-dark, 1);
                &:focus {
                    box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba($footer-dark, 1);
                }
            }
        }

    }
}