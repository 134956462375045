@import '../../sass/abstracts/variables';
@import '../../sass/abstracts/mixins';
@import '../../sass/layouts/grid';
@import '../../sass/layouts/header';
@import '../../sass/base/animations';
@import '../../sass/components/frosted-logo';

.logo-container {
    position: relative;
    transition: 0.5s;

    &:hover {
        transform: translateY(-0.05rem);
    }
    &:active {
        scale: 1.00;
    }

    &__img {
        margin-top: 2rem;
        // Logo background-color
        background-color: rgba($color-aqua, 0.6);
        //position: absolute;
        //top: 2.5rem;
        transition: 0.5s;
        //background-color: rgba(241, 160, 160, 0.5);
        box-shadow: 0rem 0rem 3rem 0.5rem rgba($color-gold, 0.8);

        &:hover {
            //box-shadow: 0.2rem 0.2rem 0.2rem rgba(red, 0.2), 0.4rem 0.4rem 0.4rem rgba(orange, 0.2), 0.6rem 0.6rem 0.6rem rgba(yellow, 0.2), 0.8rem 0.8rem 0.8rem rgba(green, 0.2), 1rem 1rem 1rem rgba(blue, 0.2), 1.2rem 1.2rem 1.2rem rgba(indigo, 0.2), 1.4rem 1.4rem 1.4rem rgba(violet, 0.2);
        }
    }
}

.logo-bg {
    
}