.form {

    &__group:not(:last-child) {
        margin-bottom: 2rem;
    }

    &__input {
        font-size: 1.5rem;
        font-family: inherit;
        color: inherit;
        padding: 1.5rem 2rem;
        border-radius: 2px;
        background-color: rgba($color-white, .5);

        border: none;
        border-bottom: 3px solid transparent;
        width: 90%;
        display: block;
        transition: all .3s ease;

        &:focus {
            outline: none;
            box-shadow: 0 1rem 2rem rgba($color-black, .1);
            border-bottom: 3px solid $color-primary;
        }

        &:focus:invalid {
            border-bottom: 3px solid orangered;
        }

        // Only Safari & Chrome
        &::-webkit-input-placeholder {
            color: $color-dark-grey-2;
        }

    }
   
    &__label {
        display: block;
        font-size: 1.2rem;
        font-weight: 700;
        margin-left: 2rem;
        margin-top: .7rem;
        transition: all .3s;
    }

    // To veil label.form__label when form.form__input:placeholder-shown
    // To unveil label.form__label when form.form__input placeholder is gone
    // Adjacent sibiling elements
    &__input:placeholder-shown + &__label {
        opacity: 0;
        visibility: hidden;
        // Moving in from top
        transform: translateY(-4rem); 
    }

    &__radio-group {
        width: 49%;
        display: inline-block;
    }

    &__radio-input {
        display: none;
    }

    &__radio-label {
        font-size: $default-font-size;
        cursor: pointer;

        position: relative;
        padding-left: 4.5rem;

        .form__radio-button {
            display: inline-block;
            height: 3rem;
            width: 3rem;
            border: 5px solid $color-primary;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: -.4rem;

            &::after {
                display: block;
                content: "";
                height: 1.3rem;
                width: 1.3rem;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: $color-primary;
                opacity: 0; // Making .form__radio-button visible only when .form__radio-input is checked
                transition: opacity .2s;
            }
        }
    }

    // When radio-input is checked => Select sibling element radio-label => its child radio-button::after
    &__radio-input:checked ~ &__radio-label &__radio-button::after {
        opacity: 1;
    }

}