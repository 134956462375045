@import "../../../sass/abstracts/variables";
@import "../../../sass/abstracts/mixins";
@import "../../../sass/layouts/grid";

.footer {
    background-color: rgba($footer-dark, 1);
}

.containerLt {
    background-color: rgba($footer-dark, 1);

    &__session {
        display: flex;
        flex-direction: row;
        margin: 0rem 2rem;
        padding: 2rem 0;
        justify-content: flex-start;
        align-items: center;

        &--img {
            margin-right: 1rem;
            transition: 0.5s;
            &:hover {
                transform: translateY(-0.3rem);
            }
        }

        &--text {
            color: $color-white;
            font-size: 1.8rem;
            margin-right: 0rem;
            transition: 0.5s;

            &:hover {
                color: $color-primary-light;
                transform: translateY(-0.3rem);
                border-radius: 100rem;
                box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba($color-primary-light, 0.2);
            }
            &:active {
                color: $color-white;
                //transform: translateY(0);
                box-shadow: 0 0 0 0;
            }
        }
    }
}
